






import { Component, Prop, Vue } from "vue-property-decorator";
import Panel from "@/components/Panel.vue";
import WorkHours from "./WorkHours.vue";

@Component({
  components: {
    WorkHours,
    Panel,
  },
})
export default class WorkHoursPanel extends Vue {
  public name = "WorkHoursPanel";

  @Prop({ default: () => "Work hours" })
  public headerTitle!: any;

  @Prop()
  public data!: any;
  @Prop({ type: Boolean, default: () => false })
  public disabled!: any;

  public builtDaysOfWeek(val: any): void {
    this.$emit("built-days-of-week", val);
  }
}
