

































import { Component, Prop, Vue } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import moment from "moment";

@Component({
  components: { TheMask },
})
export default class TimePicker extends Vue {
  public name = "TimePicker";

  public inheritAttrs = true;

  @Prop()
  public value!: any;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: any;

  @Prop({ default: () => "de", type: String })
  public locale!: any;

  @Prop({ default: () => "" })
  public inputClass!: string;

  public customTokens = {
    H: {
      pattern: /[0-2]/,
    },
    h: {
      pattern: /[0-9]/,
    },
    T: {
      pattern: /[0-5]/,
    },
    t: {
      pattern: /[0-9]/,
    },
  };

  public handleChange(value: string): void {
    this.$emit("input", value);
  }

  public handleChangeFromPicker(value: string): void {
    const formattedValue = moment(value, "HH:mm:ss").format("HH:mm");
    this.$emit("input", formattedValue);
  }
}
