











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Panel extends Vue {
  public name = "Panel";

  @Prop()
  public title!: string;
}
